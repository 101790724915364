import { DateTime } from "luxon";
import * as TimeUtils from "@/store/TimeUtils";

export default {
  getQueryStartTimeIncludedEndTimeExcluded(
    periodSelection,
    currentCustomPeriod,
    lastCustomPeriod,
    weekStartDayId,
    factoryTimezone,
  ) {
    if (periodSelection === "current_custom_period") {
      if (currentCustomPeriod) {
        return {
          startDate: currentCustomPeriod.start_date,
          endDate: currentCustomPeriod.end_date,
        };
      } else {
        // Means the custom period has been very very very recently deleted!
        let midnight = DateTime.now()
          .setZone(factoryTimezone, true)
          .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
          .toFormat(TimeUtils.DATE_FORMAT);
        return {
          startDate: midnight,
          endDate: midnight,
        };
      }
    } else if (periodSelection === "last_custom_period") {
      if (lastCustomPeriod) {
        return {
          startDate: lastCustomPeriod.start_date,
          endDate: lastCustomPeriod.end_date,
        };
      } else {
        // Means the custom period has been very very very recently deleted!
        let midnight = DateTime.now()
          .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
          .toFormat(TimeUtils.DATE_FORMAT);
        return {
          startDate: midnight,
          endDate: midnight,
        };
      }
    } else {
      const limits = this.getStartAndEndDates(periodSelection, weekStartDayId, factoryTimezone);
      return {
        startDate: limits.startDate,
        endDate: limits.endDate,
      };
    }
  },

  getStartAndEndDates(periodSelection, weekStartDayId, timezone) {
    let nowInTimezone = DateTime.now().setZone(timezone);
    switch (periodSelection.toLowerCase()) {
      case "year": {
        let startDate = nowInTimezone.set({ month: 1, day: 1, hour: 0, minute: 0, second: 0, millisecond: 0 });
        let endDate = startDate.plus({ year: 1 }).minus({ day: 1 });
        return {
          startDate: startDate.toFormat(TimeUtils.DATE_FORMAT),
          endDate: endDate.toFormat(TimeUtils.DATE_FORMAT),
        };
      }
      case "month": {
        // `current month`
        let startDate = nowInTimezone.set({ day: 1, hour: 0, minute: 0, second: 0, millisecond: 0 });
        let endDate = startDate.plus({ month: 1 }).minus({ day: 1 });
        return {
          startDate: startDate.toFormat(TimeUtils.DATE_FORMAT),
          endDate: endDate.toFormat(TimeUtils.DATE_FORMAT),
        };
      }
      case "lastmonth": {
        let startDate = nowInTimezone
          .set({ day: 1, hour: 0, minute: 0, second: 0, millisecond: 0 })
          .minus({ month: 1 });
        let endDate = startDate.plus({ month: 1 }).minus({ day: 1 });
        return {
          startDate: startDate.toFormat(TimeUtils.DATE_FORMAT),
          endDate: endDate.toFormat(TimeUtils.DATE_FORMAT),
        };
      }
      case "currentweek": {
        const today = new Date();
        const startOfWeek = this.getStartOfWeek(today, weekStartDayId);
        return {
          startDate: this.getTimestamp(
            new Date(new Date(today.getFullYear(), today.getMonth(), today.getDate()).setDate(startOfWeek)),
            false,
          ),
          endDate: this.getTimestamp(
            new Date(new Date(today.getFullYear(), today.getMonth(), today.getDate()).setDate(startOfWeek + 6)),
            false,
          ),
        };
      }
      case "lastweek": {
        const today = new Date();
        const startOfWeek = this.getStartOfWeek(today, weekStartDayId);
        return {
          startDate: this.getTimestamp(
            new Date(new Date(today.getFullYear(), today.getMonth(), today.getDate()).setDate(startOfWeek - 7)),
            false,
          ),
          endDate: this.getTimestamp(
            new Date(new Date(today.getFullYear(), today.getMonth(), today.getDate()).setDate(startOfWeek - 1)),
            false,
          ),
        };
      }
      case "day": {
        let startDate = nowInTimezone.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
        let formattedDate = startDate.toFormat(TimeUtils.DATE_FORMAT);
        return {
          startDate: formattedDate,
          endDate: formattedDate,
        };
      }
      case "lastday": {
        let startDate = nowInTimezone.set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).minus({ day: 1 });
        let formattedDate = startDate.toFormat(TimeUtils.DATE_FORMAT);
        return {
          startDate: formattedDate,
          endDate: formattedDate,
        };
      }
      default: {
        let startDate = nowInTimezone.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
        let formattedDate = startDate.toFormat(TimeUtils.DATE_FORMAT);
        return {
          startDate: formattedDate,
          endDate: formattedDate,
        };
      }
    }
  },

  getStartOfWeek(today, weekStartDayId) {
    const beforeSettingsWeekStartDay = new Date().getDay() < weekStartDayId % 7;
    return today.getDate() - today.getDay() + (weekStartDayId % 7) + (beforeSettingsWeekStartDay ? -7 : 0);
  },

  getTimestamp(date, addTime) {
    if (typeof date === "string" || date instanceof String) {
      return addTime ? date + " 00:00:00" : date;
    } else {
      let fullYear = date.getFullYear();
      let month = this.leadingZero(date.getMonth() + 1);
      let day = this.leadingZero(date.getDate());
      const formattedDate = `${fullYear}-${month}-${day}`;
      return addTime ? formattedDate + " 00:00:00" : formattedDate;
    }
  },

  leadingZero(value) {
    return ("0" + value).toString().slice(-2);
  },
};
