<template>
  <v-form :wxid="$options.name" ref="timeRangeForm" class="filter-form wx-date-range-picker">
    <v-menu
      v-model="menuWindow"
      :close-on-content-click="false"
      :left="left"
      :right="right"
      transition="fade-transition"
      class="wx-date-range-picker__menu"
      content-class="wx-date-range-picker__window"
      max-width="750px"
      nudge-bottom="5"
      rounded
      offset-y
      bottom
    >
      <template v-slot:activator="{ on, attrs }">
        <!--
          This field triggers the dialog modal ---------------------
          -->
        <wx-text-field
          @click:append="openMenu"
          :wxid="$options.name"
          :value="displayPeriod()"
          :label="$t('dateRangePicker.period.label')"
          :title="$t('dateRangePicker.period.clickToChangePeriod')"
          :class="[setPeriodFieldClass()]"
          :error-messages="validationErrors"
          class="wx-date-range-picker__text-field"
          append-icon="mdi-menu-down"
          v-on="on"
          v-bind="attrs"
          hide-details="auto"
          readonly
        />
      </template>

      <!--
        Modal dialog for selecting time range ---------------------
        -->
      <v-card :wxid="$options.name" tag="article" class="wx-panel wx-date-range-picker__card">
        <v-btn @click="closeMenu()" :title="$t('common.closeWindowHoverTitle')" class="close-window-btn" large icon>
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-row>
          <v-col cols="12" sm="5" md="5" class="first-column">
            <v-list dense>
              <v-card-title>{{ $t("dateRangePicker.period.label") }}</v-card-title>
              <v-list-item-group tag="ul" v-model="selectedPeriodIndex" color="primary">
                <v-list-item tag="li" class="period-list-item" v-for="(item, i) in periods" :key="i" :two-line="false">
                  <v-list-item-content>
                    <v-list-item-title v-text="item.text" class="wx-typo-norm" />
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-col>
          <v-col cols="12" sm="7" md="7" class="second-column pt-sm-5 d-flex flex-column">
            <v-date-picker
              v-if="!resetToggle"
              v-model="selectedDates"
              :min="minDate"
              :max="maxDate"
              :locale="datePickerLocale"
              :class="calendarCssClasses"
              color="var(--color-primary)"
              class="date-picker-selector"
              width="300px"
              show-adjacent-months
              no-title
              range
              flat
            />
            <dl class="data-inline-grid wide-dt wx-typo-sm mx-auto mt-2">
              <dt>{{ $t("dateRangePicker.period.fromStartDate") }}</dt>
              <dd>
                {{ displayStartDate() }}
              </dd>
              <dt>{{ $t("dateRangePicker.period.toEndDateIncluded") }}</dt>
              <dd>
                {{ displayEndDate() }}
              </dd>
            </dl>
            <fieldset
              class="form-footer-actions d-flex flex-row-reverse justify-center flex-column-gap mt-3 mt-sm-5 pb-4 pb-sm-2"
            >
              <wx-btn-standard
                @click="clickSubmitDates()"
                :title="$t('dateRangePicker.applyDatesHoverTitle')"
                class="submit-btn mr-0"
                color="primary"
              >
                {{ $t("dateRangePicker.applyDates") }}
              </wx-btn-standard>
              <wx-btn-standard
                @click="clickResetAction()"
                :title="$t('dateRangePicker.resetDatesHoverTitle')"
                class="reset-btn ml-0"
                outlined
                text
              >
                {{ $t("dateRangePicker.resetDates") }}
              </wx-btn-standard>
            </fieldset>
          </v-col>
        </v-row>
      </v-card>
    </v-menu>
  </v-form>
</template>

<script>
import moment from "moment-timezone";
import TimeRangeCalculator from "@/components/TimeRangeCalculator";
import WxBtnStandard from "@/components/ui/WxBtnStandard";
import WxTextField from "@/components/ui/WxTextField";
import { DateTime } from "luxon";
import * as TimeUtils from "@/store/TimeUtils";
import { mapGetters } from "vuex";

const days = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];

export default {
  name: "WxDateRangePicker",
  components: {
    WxTextField,
    WxBtnStandard,
  },
  props: {
    defaultPeriodIndex: {
      type: Number,
      default: () => 2,
    },
    timezone: {
      type: String,
      required: true,
    },
    weekFirstDay: {
      type: String,
      required: true,
    },
    lastCustomPeriod: {
      type: Object,
      default: () => null,
    },
    currentCustomPeriod: {
      type: Object,
      default: () => null,
    },
    maxPastMonths: {
      type: Number,
      default: () => 1,
    },
    maxRangeDays: {
      type: Number,
      default: () => 30, // allow ranges smaller or equal to 30 days
    },
    left: {
      type: Boolean,
      default: false,
    },
    right: {
      type: Boolean,
      default: false,
    },
    periodFieldClass: {
      type: String,
      default: () => "mt-0 mb-0",
    },
    errorMessages: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      menuWindow: false,
      selectedPeriodIndex: this.defaultPeriodIndex,
      selectedDates: ["2022-01-01", "2022-01-01"],
      weekStartDayId: 0,
      resetToggle: false,
      validationErrors: this.errorMessages,
    };
  },
  computed: {
    ...mapGetters("user", ["language", "isSimplifiedChinese"]),
    datePickerLocale() {
      switch (this.language) {
        case "fr":
          return "fr-CA";
        case "es":
          return "es-US";
        case "zh":
          return "zh-CN";
        default:
          return "en-US";
      }
    },
    periods() {
      return [
        {
          text: this.$t("dashboard.yesterday"),
          value: "lastday",
        },
        {
          text: this.$t("dashboard.currentWeek"),
          value: "currentweek",
        },
        {
          text: this.$t("dashboard.lastWeek"),
          value: "lastweek",
        },
        {
          text: this.$t("dateRangePicker.period.chooseCustomDates"),
          value: "time_range",
        },
      ];
    },
    selectedPeriod() {
      return this.periods[this.selectedPeriodIndex];
    },
    calendarCssClasses() {
      if (this.selectedPeriod && this.selectedPeriod.value === "time_range") {
        return "";
      } else {
        return "preset-period-item-selected";
      }
    },
    minDate() {
      return this.calculateMinDateTime().toFormat(TimeUtils.DATE_FORMAT);
    },
    maxDate() {
      return this.calculateMaxDateTime().toFormat(TimeUtils.DATE_FORMAT);
    },
  },
  watch: {
    errorMessages() {
      this.validationErrors = this.errorMessages;
    },
    menuWindow(newValue, oldValue) {
      if (!newValue && oldValue) this.clickSubmitDates();
    },
    timezone() {
      if (this.timezone) {
        this.setDatesFromSelectedPeriod();
        this.emitInputs();
      }
    },
    weekStartDayId() {
      this.setWeekFirstDayId();
    },
    weekFirstDay() {
      if (this.weekFirstDay) {
        this.weekStartDayId = days.indexOf(this.weekFirstDay) + 1;
        // in case the object is not set we want to return the monday intervals
        if (this.weekStartDayId === 0) {
          this.weekStartDayId = 1;
        }
        this.setDatesFromSelectedPeriod();
        this.emitInputs();
      }
    },
    selectedPeriodIndex(curr, prev) {
      if (this.selectedPeriodIndex === undefined)
        this.selectedPeriodIndex = prev;
      else if (this.selectedPeriodIndex !== null && this.selectedPeriodIndex !== undefined) {
        this.setDatesFromSelectedPeriod();
      }
      if ((curr === 3 && prev < 3) || (curr < 3 && prev === 3)) this.refreshDatePickerSelection();
    },
  },
  methods: {
    openMenu() {
      this.menuWindow = true;
    },
    setWeekFirstDayId() {
      this.weekStartDayId = days.indexOf(this.weekFirstDay) + 1;
      // in case the object is not set we want to return the monday intervals
      if (this.weekStartDayId === 0) {
        this.weekStartDayId = 1;
      }
    },
    calculateMinDateTime() {
      const daysInPast = TimeUtils.getNumberOfDaysInLastNMonths(this.maxPastMonths);
      return DateTime.now()
        .setZone(this.timezone)
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .minus({ days: daysInPast }); // Allow to navigate up to `this.maxPastMonths` months behind !
    },
    calculateMaxDateTime() {
      const now = DateTime.now()
        .setZone(this.timezone)
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
      if (this.selectedDates.length === 1) {
        const startDate = DateTime.fromISO(this.selectedDates[0], { zone: this.timezone });
        return startDate.plus({ days: this.maxRangeDays }) > now ? now : startDate.plus({ days: this.maxRangeDays });
      } else {
        return DateTime.fromISO(this.selectedDates[1], { zone: this.timezone });
      }
    },
    closeMenu() {
      this.menuWindow = false;
    },
    clickResetAction() {
      this.selectedPeriodIndex = this.defaultPeriodIndex;
      this.setDatesFromSelectedPeriod();
    },
    clickSubmitDates() {
      this.selectedPeriodIndex = JSON.parse(JSON.stringify(this.selectedPeriodIndex));
      this.menuWindow = false;
      this.emitInputs();
    },
    displayPeriod() {
      if (this.selectedPeriod.value === "time_range" || this.selectedPeriod.value === "last_custom_period") {
        return `${this.displayStartDate()} - ${this.displayEndDate()}`;
      }
      return this.selectedPeriod.text;
    },
    displayDate(dateTime) {
      let month;
      switch (dateTime.month) {
        case 1: {
          month = this.$t("common.monthAbbreviation.january");
          break;
        }
        case 2: {
          month = this.$t("common.monthAbbreviation.february");
          break;
        }
        case 3: {
          month = this.$t("common.monthAbbreviation.march");
          break;
        }
        case 4: {
          month = this.$t("common.monthAbbreviation.april");
          break;
        }
        case 5: {
          month = this.$t("common.monthAbbreviation.may");
          break;
        }
        case 6: {
          month = this.$t("common.monthAbbreviation.june");
          break;
        }
        case 7: {
          month = this.$t("common.monthAbbreviation.july");
          break;
        }
        case 8: {
          month = this.$t("common.monthAbbreviation.august");
          break;
        }
        case 9: {
          month = this.$t("common.monthAbbreviation.september");
          break;
        }
        case 10: {
          month = this.$t("common.monthAbbreviation.october");
          break;
        }
        case 11: {
          month = this.$t("common.monthAbbreviation.november");
          break;
        }
        case 12: {
          month = this.$t("common.monthAbbreviation.december");
          break;
        }
      }
      switch (this.$i18n.locale) {
        case "fr":
          return `${dateTime.day} ${month}`;
        default:
          return `${month} ${dateTime.day}`;
      }
    },
    assertStartIsBeforeEnd() {
      const currentStart = this.selectedDates[0];
      const currentEnd = this.selectedDates[1];
      if (currentStart && currentEnd) {
        const currentStartDateTime = DateTime.fromFormat(currentStart, "yyyy-LL-dd");
        const currentEndDateTime = DateTime.fromFormat(currentEnd, "yyyy-LL-dd");
        const diffInMillis = currentEndDateTime.diff(currentStartDateTime);
        if (diffInMillis < 0) {
          // It means the end is set before the start. Reverse them
          this.selectedDates[0] = currentEnd;
          this.selectedDates[1] = currentStart;
        }
      }
    },
    displayStartDate() {
      if (
        this.selectedDates &&
        this.selectedDates.length >= 0 &&
        this.selectedDates[0] &&
        this.selectedDates[0].length > 0
      ) {
        this.assertStartIsBeforeEnd();
        return this.formatDate(this.selectedDates[0]);
      }
      return "";
    },
    displayEndDate() {
      if (
        this.selectedDates &&
        this.selectedDates.length >= 1 &&
        this.selectedDates[1] &&
        this.selectedDates[1].length > 0
      ) {
        this.assertStartIsBeforeEnd();
        return this.formatDate(this.selectedDates[1]);
      }
      return "";
    },
    formatDate(date) {
      let nonLocalizedDate = this.displayDate(DateTime.fromFormat(date, "yyyy-LL-dd"));
      return this.isSimplifiedChinese ? `${nonLocalizedDate}日` : nonLocalizedDate;
    },
    emitInputs() {
      const isValid = this.$refs.timeRangeForm.validate();
      if (isValid) {
        this.assertStartIsBeforeEnd();
        this.$emit("newDateRange", {
          startDate: this.getEmitStartDate(),
          endDate: this.getEmitEndDate(),
          period: this.selectedPeriod.value,
          timezone: this.timezone ? this.timezone : this.getDetectedTimeZone(),
        });
      }
    },
    getEmitStartDate() {
      return this.selectedDates[0];
    },
    getEmitEndDate() {
      return this.selectedDates[1];
    },
    getDetectedTimeZone() {
      const zone = moment.tz.guess(true);
      return zone === null || zone === undefined ? "America/Montreal" : zone;
    },
    clickSubmitAction() {
      return this.clickSubmit();
    },
    setPeriodFieldClass() {
      return this.periodFieldClass;
    },
    setDatesFromSelectedPeriod() {
      const activeTimezone = this.timezone ? this.timezone : this.getDetectedTimeZone();
      switch (this.selectedPeriod.value) {
        case "day":
        case "lastday":
        case "currentweek":
        case "lastweek":
        case "month":
        case "lastmonth":
        case "year": {
          const period = TimeRangeCalculator.getStartAndEndDates(
            this.selectedPeriod.value,
            this.weekStartDayId,
            activeTimezone,
          );
          this.selectedDates = [period.startDate, period.endDate];
          return;
        }
        default: {
          // Should be exclusively for custom periods
          const queryStartEndTime = TimeRangeCalculator.getQueryStartTimeIncludedEndTimeExcluded(
            this.selectedPeriod.value,
            this.currentCustomPeriod,
            this.lastCustomPeriod,
            this.weekStartDayId,
            activeTimezone,
          );
          let start = DateTime.fromFormat(queryStartEndTime.startDate, "yyyy-LL-dd")
            .toFormat("yyyy-MM-dd");
          let end = DateTime.fromFormat(queryStartEndTime.endDate, "yyyy-LL-dd")
            .toFormat("yyyy-MM-dd");
          this.selectedDates = [start, end];
          return;
        }
      }
    },
    refreshDatePickerSelection() {
      this.resetToggle = true;
      this.$nextTick(() => {
        this.resetToggle = false;
      });
    },
  },
  mounted() {
    this.setWeekFirstDayId();
    this.setDatesFromSelectedPeriod();
    this.emitInputs();
  },
};
</script>

<style lang="scss" scoped>
.wx-date-range-picker {
  &.filter-form {
    width: 100%;
    min-width: 200px;
    max-width: 360px;
  }

  // .wx-period-picker__menu (dropdown)
  &__menu {
    border-radius: var(--border-radius-form-elements);
  }

  // .wx-period-picker__window (modal background)
  &__window {
    // over-rule vuetify
    ::v-deep .v-card.v-picker,
    ::v-deep .v-list,
    ::v-deep .v-picker__body {
      background-color: transparent !important;
    }
  }

  // .wx-period-picker__card.v-card
  &__card {
    width: 100%;
    min-width: 320px;
    overflow-y: hidden;

    .close-window-btn {
      position: absolute;
      z-index: 1;
      top: calc(var(--dialog-close-offset) / 3);
      right: calc(var(--dialog-close-offset) / 3);
      cursor: pointer;
    }

    // List of options
    .first-column {
      background-color: var(--color-element-layer1);
      border-top-left-radius: var(--border-radius-sm);
      border-bottom-left-radius: var(--border-radius-sm);
      padding-top: 0px;
      padding-bottom: 0px;

      .period-list-item {
        min-height: 2rem;
        font-weight: 400; /* Regular */
      }

      @media ($wx-xs-max) {
        max-width: 300px;
        margin-inline: auto;
      }
      @media ($wx-sm-min) {
        padding-inline: 0 !important;
        border-right: 1px solid var(--color-border-theme);
      }
      @media ($wx-md-min) {
        .period-list-item {
          min-height: 3rem;
        }
      }
    }

    // Calendar container
    .second-column {
      border-radius: 0 !important;

      .v-picker.v-card {
        // disable click event
        &.preset-period-item-selected {
          pointer-events: none;
        }
      }
    }

    .data-inline-grid {
      max-width: 18rem;

      dt {
        text-align: right;
        color: var(--color-text-subtle-theme);
      }
    }
    .timezone-info {
      color: var(--color-text-subtle-theme);
    }
  }
}

.d-flex > .flex-mobile-phone-row {
  align-self: flex-end;
}

/**
 * Duplicated from ui/FormFooterActions.vue
 */
.form-footer-actions {
  border-color: transparent;
  border-radius: 0;

  .v-btn {
    margin-inline: calc(var(--box-padding-admin) / 2);

    &.submit-btn {
      order: 1;
    }
    &.reset-btn {
      order: 2;
    }
  }
}
</style>
